import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import flame from './flame.png'
import flameOut from './flameOut.png'
import SingleBar from './SingleBar';
import SubscriptionForm from './SubscriptionForm'

function MathApp({userID, conversions}) {
    const [data, setData] = useState('');
    const [dataRetrieved, setDataRetrieved] = useState(false)
    const [questionText, setQuestionText] = useState([]);
    const [answer, setAnswer] = useState('');
    const [lesson, setLesson] = useState(false);
    const [correct, setCorrect] = useState(0);
    const [showCorrect, setShowCorrect] = useState(false);
    const [correctAnswer, setCorrectAnswer] = useState(false)
    const [serverAnswer, setServerAnswer] = useState(null);
    const [streakUpdated, setStreakUpdated] = useState(false);
    const [XP, setXP] = useState(0);
    const [difficulty, setDifficulty] = useState(0);
    const [welcomeText, setWelcomeText] = useState("Welcome!")
    const [outConversionText, setOutConversionText] = useState('')
    const [questionBarColor, setQuestionBarColor] = useState('#4682B4')
    const [numberInputWidth, setNumberInputWidth] = useState(15)
    const [page, setPage] = useState("lesson");
    const stripeKey = 'pk_test_51JQjZwADngI2LS7y946Ny4IsciZaBssMMs7UB4GgrAFdkCcFrepPrJ6LPe6BRjPqg48uPzMyn8FnaawA20wApETd00Uig1iG1O';

    useEffect(() => {
        retrieveData(); 
    }, []);



    const addText = (val) => {
        setAnswer((prev) => (prev || "") + val);
      }

      const removeText = () => {
        setAnswer((prev) => prev.slice(0, -1));
      }

      const clearText = () => {
        setAnswer('');
      }

    const handleKeyPress = useCallback((event) => {
        event.preventDefault(); // Prevent default button behavior 

        console.log(event.key);
        let inputString = '0123456789.-'
        if (difficulty > 7500){
          inputString += 'e'
        }
        if (difficulty > 25000){
          inputString += 'x'
        }
        if (difficulty > 0){
          inputString += 'i+-'
        }
        if (difficulty > 0){
          inputString += '[] '
        }
        if (difficulty > 0){
          inputString += 'jk'
        }
        
        if (lesson){
            if (event.key === 'Enter') {
                submitAnswer();
              } else if (event.key === 'Backspace') {
                removeText(); // Remove last character
              } else if (inputString.includes(event.key)) {
                addText(event.key);
              }
              else if (event.key === 'Delete'){
                clearText();
              }
        } 
        else {
            if (event.key === 'Enter') {
                startLesson();
              }
        }   
      }, [addText, answer, removeText, clearText]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
      }, [handleKeyPress]);

    const retrieveData = async () => {
        const response = await fetch('https://api.opticognito.au/retrieve-data', {
            method: 'POST',
            body: JSON.stringify({
                userID: userID,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json();   
        console.log(data);
        setData(data);
        const now = Date.now();
        const day = Math.floor(now / 1000 / 86400)
        setStreakUpdated(day === data.data.streakDay)
        setXP(data.data.XP)
        setDifficulty(Math.floor(data.data.difficulty))
        if (data.data.XP > 0){
            setWelcomeText("Welcome Back!")
        }
        setDataRetrieved(true);
    }

    const getQuestion = async () => {
        setAnswer('');
        setOutConversionText('');
        const response = await fetch('https://api.opticognito.au/get-question', {
            method: 'POST',
            body: JSON.stringify({
                userID: userID,
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        })

        const question = await response.json();

        const numbers = question.data['numbers'];
        const symbols = question.data['symbols'];
        const complex = question.data['complex'];
        const quaternion = question.data['quaternion'];
        const vector = question.data['vector'];
        const matrix = question.data['matrix'];
        const brackets = question.data['brackets'];

        console.log(brackets)

        if (complex){
          setNumberInputWidth(20)
        }
        else if (quaternion || vector){
          setNumberInputWidth(30)
        }
        else if (matrix){
          setNumberInputWidth(40)
        }
        else{
          setNumberInputWidth(15)
        }

        const symbolText = {
            '+': ' + ',
            '-': ' - ',
            '*': ' x ',
            '/': ' ÷ ',
            '%': ' % ',
            '!': '!',
            '^': '',
            'P': 'P',
            'C': 'C',
            'log': 'log',
            'sin': 'sin',
            'cos': 'cos',
            'tan': 'tan',
            'asin': 'asin',
            'acos': 'acos',
            'atan': 'atan',
            'cosec': 'cosec',
            'sec': 'sec',
            'cot': 'cot',
            'acosec': 'acosec',
            'asec': 'asec',
            'acot': 'acot',
            'calc': 'calc',
            'fn': 'fn',
            '|': '|',
            '.': ' . '
        }

        let addString = "";

        console.log(symbols);

        for (let i=0; i<numbers.length; i++){
          for (let j=0; j<=brackets.length; j++){
            if (brackets[j] == i && j % 2 == 0){
              addString += '('
            }
            else if (brackets[j] == i && j % 2 == 1){
              addString += ')'
            }
          }
            if (symbols[i] === 'fn'){
              addString += 'f(x) = '
              for (let j=numbers.length - 2; j>= 0; j--){
                addString += `${numbers[j] >= 0 ? (j < numbers.length - 2 ? '+' : '') : '-'} ${Math.abs((numbers[j]) > 1) || j === 0 && (Math.abs(numbers[j]) > 0) ? Math.abs(numbers[j]) : ''}${j > 0 ? 'x': ''}<sup>${j > 1 ? j : ''}</sup>`
                addString += `${numbers[j] >= 0 ? (j < numbers.length - 2 ? '+' : '') : '-'} ${Math.abs((numbers[j]) > 1) || j === 0 && (Math.abs(numbers[j]) > 0) ? Math.abs(numbers[j]) : ''}${j > 0 ? 'x': ''}<sup>${j > 1 ? j : ''}</sup>`
              }

              addString += `, x = ${numbers[numbers.length - 1]}`
              break;
            }
            if (symbols[i] === 'calc'){
              addString += `<sup>d</sup>/<sub>dx</sub>(`
              for (let j=numbers.length - 2; j>= 0; j--){
                addString += `${numbers[j] >= 0 ? (j < numbers.length - 2 ? '+' : '') : '-'} ${Math.abs((numbers[j]) > 1) || j === 0 && (Math.abs(numbers[j]) > 0) ? Math.abs(numbers[j]) : ''}${j > 0 ? 'x': ''}<sup>${j > 1 ? j : ''}</sup>`
              }
              addString += `), x = ${numbers[numbers.length - 1]}`
              break;
            }
            if (symbols[i] == 'int'){
              const lowerLimit = numbers[numbers.length - 1][0];
              const upperLimit = numbers[numbers.length - 1][1];
              addString += `∫<sub>${lowerLimit}</sub><sup>${upperLimit}</sup>(`
              for (let j=numbers.length - 2; j>= 0; j--){
                addString += `${numbers[j] >= 0 ? (j < numbers.length - 2 ? '+' : '') : '-'} ${Math.abs((numbers[j]) > 1) || j === 0 && (Math.abs(numbers[j]) > 0) ? Math.abs(numbers[j]) : ''}${j > 0 ? 'x': ''}<sup>${j > 1 ? `${j} ` : ''}</sup>`
              }
              addString += `) dx`
              break;
            }
            if (symbols[i] === 'sqrt'){
              addString += `&radic;<msqrt style={{"border-top": "2px solid"}}>${numbers[i].toString()}</msqrt></span>`
                continue;
            }
            if (i !== numbers.length - 1) {
                if (symbols[i] === '^' && !complex) {
                  addString += `${numbers[i].toString()}${symbolText[symbols[i]]}<sup>${numbers[i + 1].toString()}</sup>`
                  // Skip the next number as it's already used as the exponent
                  i++;
                  // If there is another symbol, display it:
                  if (i <= symbols.length - 1){
                    addString += `${symbolText[symbols[i]]}`
                  }
                }
                else if (symbols[i] === '^' && complex){
                    addString += `(${numbers[i][0] >= 0 ? '' : '-'}${Math.abs(numbers[i][0].toString())} ${numbers[i][1] >= 0 ? '+' : '-'} ${Math.abs(numbers[i][1]) > 1 ? Math.abs(numbers[i][1].toString()) : ''}i)
                    ${symbolText[symbols[i]]}
                    <sup>${numbers[i + 1].toString()}</sup>`
                  // Skip the next number as it's already used as the exponent
                  i++;
                  // If there is another symbol, display it:
                  if (i <= symbols.length - 1){
                    addString += `${symbolText[symbols[i]]}`
                  }
                }
                else if (symbols[i] === '^' && quaternion){
                  addString += `(${numbers[i][0] >= 0 ? '' : '-'}${Math.abs(numbers[i][0]) > 0 ? Math.abs(numbers[i][0]).toString() : ''} ${numbers[i][1] >= 0 ? '+' : '-'} ${Math.abs(numbers[i][1]) > 1 ? Math.abs(numbers[i][1]).toString() : ''}i ${numbers[i][2] >= 0 ? '+' : '-'} ${Math.abs(numbers[i][2]) > 1 ? Math.abs(numbers[i][2]).toString() : ''}j ${numbers[i][3] >= 0 ? '+' : '-'} ${Math.abs(numbers[i][3]) > 1 ? Math.abs(numbers[i][3]).toString() : ''}k)
                  ${symbolText[symbols[i]]}
                  <sup>${numbers[i + 1].toString()}</sup>`
                  i++;
                  if (i <= symbols.length - 1){
                    addString += `${symbolText[symbols[i]]}`
                  }
                }
                else if (symbols[i] == 'log'){
                  addString += `${symbolText[symbols[i]]}<sub>${numbers[i].toString()}</sub>${numbers[i + 1].toString()}`
                      // Skip the next number as it's already used as the exponent
                      i++;
                      // If there is another symbol, display it:
                      if (i <= symbols.length - 1){
                        addString += `${symbolText[symbols[i]]}`
                      }
                } else if (!complex && !vector && !matrix && !quaternion) {
                  addString += `${numbers[i].toString()} ${symbolText[symbols[i]]}`
                }
                else if (complex){
                  addString += `(${numbers[i][0] >= 0 ? '' : '-'}${Math.abs(numbers[i][0].toString())} ${numbers[i][1] >= 0 ? '+' : '-'} ${Math.abs(numbers[i][1]) > 1 ? Math.abs(numbers[i][1].toString()) : ''}i)
                  ${symbolText[symbols[i]]}`
                }
                else if (quaternion){
                  addString += `(${numbers[i][0] >= 0 ? '' : '-'}${Math.abs(numbers[i][0]) > 0 ? Math.abs(numbers[i][0]).toString() : ''} ${numbers[i][1] >= 0 ? '+' : '-'} ${Math.abs(numbers[i][1]) > 1 ? Math.abs(numbers[i][1]).toString() : ''}i ${numbers[i][2] >= 0 ? '+' : '-'} ${Math.abs(numbers[i][2]) > 1 ? Math.abs(numbers[i][2]).toString() : ''}j ${numbers[i][3] >= 0 ? '+' : '-'} ${Math.abs(numbers[i][3]) > 1 ? Math.abs(numbers[i][3]).toString() : ''}k)
                  ${symbolText[symbols[i]]}`
                }
                else if (vector){
                  addString += `[${numbers[i][0].toString()} ${numbers[i][1].toString()}] ${symbolText[symbols[i]]}`
                }
                else if (matrix){
                  const rowLength = Math.sqrt(numbers.length)
                  addString += '['
                  for (let j=0; j<rowLength; j++){
                    addString += '['
                    for (let k=0; k<rowLength; k++){
                        addString += (k !== rowLength - 1) ? `${numbers[i][j][k].toString()} ` : `${numbers[i][j][k].toString()}`;
                    }
                      addString += ']\n'
                  }
                  addString += `]\n${symbolText[symbols[i]]}`
                }
              } else if (matrix){
                const rowLength = Math.sqrt(numbers.length)
                  let addString = '['
                  for (let j=0; j<rowLength; j++){
                    addString += '['
                    for (let k=0; k<rowLength; k++){
                      addString += (k !== rowLength - 1) ? `${numbers[i][j][k].toString()} ` : `${numbers[i][j][k].toString()}`;            
                    }
                    addString += ']\n'
                  }
                  addString += ']'
              }
              else if (vector && symbols[i] === '|'){
                addString += `||[${numbers[i][0].toString()} ${numbers[i][1].toString()}]||`
              }
              else if (vector){
                addString += `[${numbers[i][0].toString()} ${numbers[i][1].toString()}]`
              }

              else if (complex && symbols[i] === '|') {
                addString += `|${numbers[i][0] >= 0 ? '' : '-'}${Math.abs(numbers[i][0].toString())} ${numbers[i][1] >= 0 ? '+' : '-'} ${Math.abs(numbers[i][1].toString())}i|`
              }
              else if (complex) {
                addString += `(${numbers[i][0] >= 0 ? '' : '-'}${Math.abs(numbers[i][0].toString())} ${numbers[i][1] >= 0 ? '+' : '-'} ${Math.abs(numbers[i][1].toString())}i)`
              }
              else if (quaternion && symbols[i] === '|'){
                addString += `|${numbers[i][0] >= 0 ? '' : '-'}${Math.abs(numbers[i][0]) > 0 ? Math.abs(numbers[i][0]).toString() : ''} ${numbers[i][1] >= 0 ? '+' : '-'} ${Math.abs(numbers[i][1]) > 1 ? Math.abs(numbers[i][1]).toString() : ''}i ${numbers[i][2] >= 0 ? '+' : '-'} ${Math.abs(numbers[i][2]) > 1 ? Math.abs(numbers[i][2]).toString() : ''}j ${Math.abs(numbers[i][3]) >= 0 ? '+' : '-'} ${Math.abs(numbers[i][3]) > 1 ? Math.abs(numbers[i][3]).toString() : ''}k|` 
              }
              else if (quaternion){
                addString += `(${numbers[i][0] >= 0 ? '' : '-'}${Math.abs(numbers[i][0]) > 0 ? Math.abs(numbers[i][0]).toString() : ''} ${Math.abs(numbers[i][1]) >= 1 ? '+' : '-'} ${Math.abs(numbers[i][1]) > 1 ? Math.abs(numbers[i][1]).toString() : ''}i ${Math.abs(numbers[i][2]) >= 1 ? '+' : '-'} ${Math.abs(numbers[i][2]) > 1 ? Math.abs(numbers[i][2]).toString() : ''}j ${Math.abs(numbers[i][3]) >= 1 ? '+' : '-'} ${Math.abs(numbers[i][3]) > 1 ? Math.abs(numbers[i][3]).toString() : ''}k)` 
              }
              else {    
                addString +=  `${numbers[i].toString()}`         
                if (symbols[i] === '!'){
                  addString += `${symbolText[symbols[i]]}`
                }
                for (let j=0; j<conversions.length; j++){
                    if (symbols[i] === conversions[j]){
                        console.log("Matched conversion")
                        const from = symbols[i].split('-')[0];
                        const to = symbols[i].split('-')[1];
                        console.log(conversions[j])
                        console.log(from, to)
                        addString += `${from}`
                        setOutConversionText(to)
                        break;
                    }
                }
              }          
        }

        for (let j=0; j<=brackets.length; j++){
          if (brackets[j] == numbers.length && j % 2 == 0){
            addString += '('
          }
          else if (brackets[j] == numbers.length && j % 2 == 1){
            addString += ')'
          }
        }

        addString += ' = '
        setQuestionText(addString);
    }

    const startLesson = async () => {
        setLesson(true);
        await fetch('https://api.opticognito.au/start-lesson', {
            method: 'POST',
            body: JSON.stringify({
                userID: userID,
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        const inputElement = document.getElementsByClassName('numberInput')[0];

        // Make the input element unselectable
        inputElement.style.pointerEvents = 'none';    
        getQuestion();
    }

    const showCorrectTemp = async () => {
        setShowCorrect(true);
        setTimeout(() => {
            setShowCorrect(false);
            setQuestionBarColor('#4682B4')
        }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {      
        if (lesson && correct >= 100) {
            retrieveData();
            setCorrect(0);
            setLesson(false);
        }
    }, [correct]);

    const submitAnswer = async () => {
        console.log("Submitting answer");
        console.log(answer.length)
        if (answer !== ""){
            const response = await fetch('https://api.opticognito.au/submit-answer', {
                method: 'POST',
                body: JSON.stringify({
                    userID: userID,
                    answer: answer
                }),
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            const data = await response.json();
            console.log(data.correct);
            console.log(data.answer)
            setServerAnswer(data.answer)
            if (data.correct && !data.timeout){     
                setCorrect(correct + 3) 
                showCorrectTemp();
                setCorrectAnswer("Excellent!")
                setQuestionBarColor('#34C759')
            }
            else if (data.correct){
                setCorrect(correct + 2) 
                setCorrectAnswer("Correct")
                showCorrectTemp();
                setQuestionBarColor('#707070')
            }
            else {
                showCorrectTemp();
                setCorrectAnswer("Incorrect")
                setQuestionBarColor('#FF3737')
            }
            getQuestion();
            setAnswer('');
        }
        
    }

    const determinePercentage = (target) => {
        var output = 0;
        if (difficulty > target){
            output = 100;
        }
        else {
            output = 100 / (1 + Math.log(target/difficulty))
        }
        return output;
    }

    const mathConcepts = [
        { name: "Addition / Subtraction", value: 1 },
        { name: "Multiplication / Division", value: 10 },
        { name: "Negative Numbers", value: 50 },
        { name: "Exponents", value: 100 },
        { name: "Conversions", value: 250},
        { name: "Modulus", value: 500 },
        { name: "Rational Division", value: 750 },
        { name: "Longer Expressions", value: 1000 },
        { name: "Brackets", value: 1250 },
        { name: "Factorials", value: 1500 },
        { name: "Permutations and Combinations", value: 2500 },
        { name: "Discrete Logarithms", value: 3000},
        { name: "Square Roots", value: 4000},
        { name: "Logarithms", value: 5000 },
        { name: "Conversions - Scientific", value: 7500},
        { name: "Trigonometric Functions", value: 10000 },
        { name: "Inverse Trigonometric Functions", value: 13333 },
        { name: "Function Evaluation", value: 16667 },
        { name: "Alternative Trigonometric Functions", value: 20000 },
        { name: "Calculus", value: 23333 },
        { name: "Integration", value: 26667 },
        { name: "Complex Numbers", value: 30000 },
        { name: "Vectors - Addition", value: 35000 },
        { name: "Vectors - Norm", value: 40000 },
        { name: "Vectors - Dot Product", value: 45000 },
        { name: "Irrational Logarithms and Exponents", value: 50000 },
        { name: "Matrices - Addition", value: 55000 },
        { name: "Matrices - Vector Vector Multiplication", value: 60000 },
        { name: "Matrices - Matrix Vector Multiplication", value: 65000 },
        { name: "Matrices - Matrix Matrix Multiplication", value: 70000 },
        { name: "Quaternions - Addition/Subtraction", value: 75000 },
        { name: "Quaternions - Multiplication", value: 80000 },
        { name: "Quaternions - Division", value: 85000 },
        { name: "Matrices - Determinant", value: 90000 },
        { name: "Quaternions - Norm", value: 95000 },
        { name: "Hyperbolic Trigonometric Functions", value: 100000 },
        { name: "Error Function", value: 105000 },
        { name: "Quaternions - Power", value: 110000 },
        { name: "Matrices - Inverse", value: 115000 },
        { name: "Quaternions - Rotation", value: 120000 },
        { name: "Matrices - Power", value: 125000 },
        { name: "Gamma Function", value: 130000 },
      ];

      const MathConceptsList = () => {
        return (
          <div>
            {mathConcepts.map((concept) => (
              <div key={concept.value}>
                {concept.name}<br />
                <SingleBar percentage={determinePercentage(concept.value)} /><br />
              </div>
            ))}
          </div>
        );
      };

      const exitLesson = () => {
        setCorrect(0);
        setLesson(false);
        retrieveData();
      }
    
    return (
        
        <div>
            {dataRetrieved && <>
                {!lesson && <>
                <div className = 'streak'>
                    {streakUpdated ? <img src={flame} alt='Flame Illuminated'/> : <img src = {flameOut} alt='Flame Extinguished'/>}
                    <span className='streakNumber'>{data.data['streak']} &nbsp;&nbsp;
                    XP {XP} &nbsp;&nbsp;
                    Elo {difficulty}
                    </span>
                </div>
                <nav><a onClick={() => {setPage("lesson")}}>Lessons</a><a onClick={() => {setPage("pro")}}>Pro</a></nav>
                {page === "lesson" && <>
                  <button className='startLesson' onClick = {startLesson}>Start Lesson</button><br/><br/>
                  <p className = 'details'>
                  <div className = 'skills'>
                  {MathConceptsList()}
                  </div>
                  The main thing that the Elo controls is the maximum size of the numbers used in the questions.<br/><br/>
                  Required precision increases with Elo.<br/><br/> 
                  Use a notebook if you wish to devise your own solving methodologies, but getting answers correct will not be enough to raise your Elo alone. You also need to be fast.<br/>
                </p>
                </>}
                {page === "pro" && <>Here are the benefits of our Pro membership, which gives you the ultimate control in your lesson planning!<br/>
                - Perform lessons in specific topics<br/>
                - Perform lessons in locked topics<br/>
                - Customise the elo algorithm to allow for longer form questions, rather than just mental math<br/>
                - The removal of all ads<br/>
                - Increased priority for the building of lower latency servers
                <SubscriptionForm stripeKey={stripeKey} userID={userID}/>
                </>}
                
                </>}
                {lesson && (
                    <>
                    <button className = 'exitLesson' onClick={exitLesson}>Exit Lesson</button>
                    <div className='progress'>
                        <p>{correct}%</p>
                        <SingleBar className = 'progressBar' percentage={correct} color = {questionBarColor}/>    
                    </div>
                    <div className='questionContainer'>
                    <div className = 'correct'> {showCorrect && <> {correctAnswer} {serverAnswer}  </>}<br /></div>
                    <div className = 'question' dangerouslySetInnerHTML={{ __html: typeof questionText === 'string' ? questionText.replace("\n", "<br/>") : questionText }}></div>  
                    &emsp;
                    <input className = 'numberInput' style={{ width: `${numberInputWidth}vw`}} value={answer} readOnly={true}/>
                    <span className = 'question'>{outConversionText}</span>
                    <br/><br/><br/>
                    <button className='numberButton' onClick={() => {addText(1)}}>1</button><button className='numberButton' onClick={() => {addText(2)}}>2</button><button className='numberButton' onClick={() => {addText(3)}}>3</button><button className='numberButton' onClick={() => {removeText()}}>DEL</button>{difficulty > 35000 ? <button className='numberButton' onClick={() => {addText('[')}}>[</button> : <></>} {difficulty > 35000 ? <button className='numberButton' onClick={() => {addText(']')}}>]</button> : <></>}{difficulty > 35000 ? <button className='numberButton' onClick={() => {addText(' ')}}>_</button> : <></>}<br/>
                    <button className='numberButton' onClick={() => {addText(4)}}>4</button><button className='numberButton' onClick={() => {addText(5)}}>5</button><button className='numberButton' onClick={() => {addText(6)}}>6</button><button className='numberButton' onClick={() => {addText('-')}}>-</button>
                    {difficulty > 30000 ? <button className='numberButton' onClick={() => {addText('+')}}>+</button> : <></>}
                    <br/>
                    <button className='numberButton' onClick={() => {addText(7)}}>7</button><button className='numberButton' onClick={() => {addText(8)}}>8</button><button className='numberButton' onClick={() => {addText(9)}}>9</button>{difficulty > 500 ? <button className='numberButton' onClick={() => {addText('.')}}>.</button> : <></>}  {difficulty > 75000 ? <button className='numberButton' onClick={() => {addText('j')}}>j</button> : <></>}   
                    <br/>
                    <button className='numberButton' onClick={() => {addText(0)}}>0</button><button className='numberButton' onClick={() => {clearText()}}>CLS</button>
                    {difficulty > 7500 ? <button className='numberButton' onClick={() => {addText('e')}}>e</button> : <></>}
                    {difficulty > 23333 ? <button className='numberButton' onClick={() => {addText('x')}}>x</button> : <></>}
                    {difficulty > 30000 ? <button className='numberButton' onClick={() => {addText('i')}}>i</button> : <></>}{difficulty > 75000 ? <button className='numberButton' onClick={() => {addText('k')}}>k</button> : <></>}                     
                    <br/>                   
                    <button tabIndex = '-1' className='submitButton' onClick={submitAnswer}>Submit</button>
                    </div>
                    </>
                )}
                </>
            }
        </div>
    );
}

export default MathApp;
