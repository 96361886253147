import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

function SubscriptionForm({stripeKey, cookie}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const stripePromise = loadStripe(stripeKey)
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
      setError(null);
  
      try {
        const stripe = await stripePromise;
        const response = await fetch(`https://api.opticognito.au/create-subscription/${cookie}`, { 
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        console.log(data.sessionId)

        await stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });
  
      } catch (error) {
        console.error('Error creating subscription:', error);
        setError('Failed to create subscription. Please try again.');
      } finally {
        setLoading(false);
      }
    };

return (
    <form onSubmit={handleSubmit}>
      {/* Add any necessary form fields for customer information here (e.g., email) */}
      {/* Example:
      <label>
        Email:
        <input type="email" name="email" required />
      </label>
      */}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button type="submit" disabled={loading}>
        {loading ? 'Creating Subscription...' : 'Subscribe (A$10/month)'}
      </button>
    </form>
  );
}

export default SubscriptionForm;