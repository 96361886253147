import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

function SubscriptionForm({stripeKey, userID}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const stripePromise = loadStripe(stripeKey)
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
      setError(null);
  
      try {
        const stripe = await stripePromise;
        const response = await fetch('http://localhost:3004/create-subscription', { //Your backend endpoint
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
             userID: userID
          }),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        console.log(data.sessionId)

        fetch('http://localhost:3004/create-subscription-backend', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userID: userID,
                sessionID: data.sessionId
            }),
        })
  
        // Redirect to Stripe Checkout
        const result = await stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });

        
  
        if (result.error) {
          // Handle errors appropriately (e.g., display an error message)
          console.error(result.error.message);
          setError(result.error.message);
        }
  
      } catch (error) {
        console.error('Error creating subscription:', error);
        setError('Failed to create subscription. Please try again.');
      } finally {
        setLoading(false);
      }
    };

return (
    <form onSubmit={handleSubmit}>
      {/* Add any necessary form fields for customer information here (e.g., email) */}
      {/* Example:
      <label>
        Email:
        <input type="email" name="email" required />
      </label>
      */}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button type="submit" disabled={loading}>
        {loading ? 'Creating Subscription...' : 'Subscribe (A$10/month)'}
      </button>
    </form>
  );
}

export default SubscriptionForm;