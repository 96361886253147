// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCouD0k1nn2F6Py6zrPozgIeQxu7a7YqKo",
  authDomain: "math-app-bbad0.firebaseapp.com",
  projectId: "math-app-bbad0",
  storageBucket: "math-app-bbad0.appspot.com",
  messagingSenderId: "519416408532",
  appId: "1:519416408532:web:951a6c4c04ac559a62ce41",
  measurementId: "G-8S9N8P43HP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider }