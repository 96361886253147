import './App.css';
import React, { useState, useEffect } from 'react';
import MathApp from './MathApp';


function App() {

  const [authCookie, setAuthCookie] = useState(null);
  const [signIn, setSignIn] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const generatePairs = (conversionSet, pairs) => {
    for (let key in conversionSet){
        for (let key2 in conversionSet){
            if (key !== key2){
                pairs.push(conversionSet[key] + '-' + conversionSet[key2])
            }             
        }
    }           
}


const generateConversions = () => {

    const lengthUnits = [
  'm', 'km', 'hm', 'dam', 'dm', 'cm', 'mm', 'μm', 'nm', 'pm', 'fm', 'am',
  'in', 'ft', 'yd', 'mi', 'Å', 'au', 'ly', 'pc',
  'Earth_radius', 'Moon_radius', 'Sun_radius', 'Jupiter_radius'
];
    const timeUnits = [
      'as', 'fs', 'ps', 'ns', 'μs', 'ms', 's', 'min', 'hr',
      'day', 'week', 'fortnight', 'month', 'year', 'decade', 'century', 'millennium',
      'jiffy', 'shake', 'sidereal_day', 'tropical_year', 'lunar_month'
    ];
    const temperatureUnits = ['K', 'C', 'F', 'R'];
    const volumeUnits = [
      'L', 'mL', 'dL', 'cL', 'μL', 'nL',
      'gallon', 'pint', 'quart', 'cup', 'fl_oz', 'tbsp', 'tsp'
    ];
    const massUnits = [
      'kg', 'g', 'mg', 'μg', 'ng', 'pg', 'fg',
      'lb', 'oz', 'st',
      'proton_mass', 'electron_mass', 'hydrogen_mass', 'neutron_mass', 'amu',
      'Earth_mass', 'Moon_mass', 'Sun_mass'
    ];

    const angleUnits = ['°', 'rad']
    const energyUnits = [
      'J', 'kJ', 'MJ', 'GJ', 'TJ', 'cal', 'kcal', 'eV', 'kWh',
      'MWh', 'GWh', 'TWh', 'PWh', 'kWh_day', 'MWh_day', 'GWh_day',
      'kWh_year', 'MWh_year', 'GWh_year', 'TWh_year'
    ];
    const currencies = [
      'AUD', 'BRL', 'CAD', 'CHF', 'CNY', 'EUR', 'GBP', 'HKD', 'INR', 'JPY',
      'KRW', 'MXN', 'NOK', 'NZD', 'RUB', 'SEK', 'SGD', 'ZAR', 'TRY', 'ILS', 'USD'
    ];

    const allUnits = [
      lengthUnits, timeUnits, temperatureUnits, volumeUnits, massUnits, angleUnits, energyUnits, currencies
    ];


    let pairs = [];
    for (const unitSet of allUnits) {
        generatePairs(unitSet, pairs);
    }
    console.log(pairs)
    return pairs
}

const conversions = generateConversions()
console.log(conversions)


  useEffect(async () => {
    let cookie = localStorage.getItem('cookie')
    if (cookie){
      const response = await fetch(`https://api.opticognito.au/authenticate/${cookie}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          }
      })
      const data = await response.json();  
      if (data.success){
        setAuthCookie(cookie)
      }
    }
  }, []);

  const handleSignIn = async () => {
    const response = await fetch('https://api.opticognito.au/authenticate', {
        method: 'POST',
        body: JSON.stringify({
            username, password
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    })

    const data = await response.json();
    if (data.success){
      setAuthCookie(data.cookie)
      localStorage.setItem('cookie', data.cookie)
    }
    else {
      alert(data.text)
    }
    
  };

  const handleSignOut = () => {
    setAuthCookie(null);
  };

  const handleRegistration = async () => {
    if (confirmPassword == password){
      const response = await fetch('https://api.opticognito.au/register', {
            method: 'POST',
            body: JSON.stringify({
                username, password
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json();
        alert(data.text)

    }
    else{
      alert("Confirm Password and Password must match.")
    }
  }

  return (
    <div className="App">
        {authCookie ? (
          <>
          <div className="sign-out-container">
            <button className="sign-out-button" onClick={(handleSignOut)}>Sign Out</button><br/><br/>
          </div>
          <div className="auth-container">
            <MathApp cookie={authCookie} conversions={conversions}/> 
           </div>
          </>
          ) : (<>
            {signIn ? (
          <div>
            <h1>OptiCognito Sign In</h1>
            <label>Username</label><br/>
            <input type='text' value={username} className="textInput" onChange={(e) => setUsername(e.target.value)}/><br/>
            <label>Password</label><br/>
            <input type='password' value={password}  className="textInput"  onChange={(e) => setPassword(e.target.value)}/><br/>

            <button className="sign-in-button" onClick={handleSignIn}>
              Sign In
            </button>

            <button className="sign-in-button" onClick={() => setSignIn(false)}>
              Register
            </button>
          </div>
        ) : (<div>
            <h1>OptiCognito User Registration</h1>
            <label>Username</label><br/>
            <input type='text' value={username} className="textInput" onChange={(e) => setUsername(e.target.value)}/><br/>
            <label>Password</label><br/>
            <input type='password' value={password} className="textInput" onChange={(e) => setPassword(e.target.value)}/><br/>
            <label>Confirm Password</label><br/>
            <input type='password' value={confirmPassword} className="textInput" onChange={(e) => setConfirmPassword(e.target.value)}/><br/>
            <button className="sign-in-button" onClick={handleRegistration}>
                Register
            </button>
            <button className="sign-in-button" onClick={() => setSignIn(true)}>
              Sign In
            </button>
        </div>
      )}
      </>
  )}
  </div>
  )
};

export default App;
