import React from 'react';

const SingleBar = ({ percentage, color }) => {
    if (color === undefined){
        if (percentage === 100){
            color = '#8DD88D'
        }
        else {
            color = '#035803'
        }
    }
    return (
      <div
        style={{
          width: '100%',
          height: '20px',
          borderRadius: '15px',
          overflow: 'hidden',
          marginTop: '5px'
        }}
      >
        <div
          style={{
            width: `${percentage}%`,
            height: '100%',
            backgroundColor: color,
            
          }}
        />
      </div>
    );
  };

export default SingleBar;