import React, { useState, useEffect } from 'react';

function EloTime({cookie}) {
  const [eloTime, setEloTime] = useState(0);

  useEffect(() => {
    const fetchEloTime = async () => {
      try {
        const response = await fetch(`https://api.opticognito.au/get-elo-time/${cookie}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setEloTime(data.eloTime || 0); // Handle case where eloTime might be missing
      } catch (error) {
        console.error("Error fetching initial Elo time:", error);
      }
    };

    if (cookie) { 
      fetchEloTime();
    }
  }, [cookie]); 

  const changeEloTime = async (event) => { 

    const newEloTime = parseInt(event.target.value, 10) || 0; 
    setEloTime(newEloTime); 

    await fetch(`https://api.opticognito.au/change-elo-time/${cookie}/${newEloTime}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

  return (
    <div>
      Elo Time: <input type='number' id='eloTime' onInput={changeEloTime} value={eloTime} />
    </div>
  );
}

export default EloTime;